export default {
    data() {
      return {
        searchValue: "",
        headers: [
          {
            text: "Adı Soyadı",
            value: "name",
            sortable: true,
          },
          {
            text: "E-Posta Adresi",
            value: "email",
            sortable: true,
          },
          {
            text: "Firma",
            value: "status",
            sortable: true,
          },
  
          {
            text: "Oluşturulma Tarihi",
            value: "created",
            sortable: true,
          },
  
          {
            text: "",
            value: "actions",
            sortable: false,
            align: "direction-left",
          },
        ],
        Item: [
          {
            name: "Aysoy Ülker",
            avator: require('@/assets/images/user/7.jpg'),
            email: "durmuskorkmazalp@zorlu.info",
            status: "BIM-Bim",
            created: "17-11-2023",
          },
          {
            name: "Gülbani Akdeniz",
            avator: require('@/assets/images/user/7.jpg'),
            email: "kete79@guclu.net",
            status: "MIG-Migros",
            created: "13-09-2022",
          },
          {
            name: "Çeviköz Nebih Akça",
            avator: require('@/assets/images/user/7.jpg'),
            email: "keteseven@gmail.com",
            status: "BIM-Bim",
            created: "18-07-2023",
          },
          {
            name: "Tülin Akçay Çamurcuoğlu",
            avator: require('@/assets/images/user/7.jpg'),
            email: "ergulozyurt@gmail.com",
            status: "MIG-Migros",
            created: "30-05-2022",
          },
          {
            name: "Çelikkan Yıldırım",
            avator: require('@/assets/images/user/7.jpg'),
            email: "bsama@yahoo.com",
            status: "MIG-Migros",
            created: "08-06-2022",
          },
          {
            name: "Gülhisar Çorlu",
            avator: require('@/assets/images/user/7.jpg'),
            email: "hihsanoglu@migros.net",
            status: "BIM-Bim",
            created: "21-07-2022",
          },
          {
            name: "Özkent Akar",
            avator: require('@/assets/images/user/7.jpg'),
            email: "bediriyeertas@hotmail.com",
            status: "BIM-Bim",
            created: "18-08-2022",
          },
          {
            name: "İhsan Aslan",
            avator: require('@/assets/images/user/7.jpg'),
            email: "nilcanakdeniz@yahoo.com",
            status: "MIG-Migros",
            created: "04-01-2022",
          },
          {
            name: "Özdeş Armağan Aksu ",
            avator: require('@/assets/images/user/7.jpg'),
            email: "qakdeniz@hotmail.com",
            status: "MIG-Migros",
            created: "22-04-2023",
          },
          {
            name: "Sezer Hayrioğlu Sezer",
            avator: require('@/assets/images/user/7.jpg'),
            email: "copur97@yahoo.com",
            status: "MIG-Migros",
            created: "23-08-2023",
          },
          {
            name: "Duran Sarıcabay ",
            avator: require('@/assets/images/user/7.jpg'),
            email: "fami33@yahoo.com",
            status: "BIM-Bim",
            created: "01-11-2023",
          },
          {
            name: "Ahmet Akça",
            avator: require('@/assets/images/user/7.jpg'),
            email: "altugarsoy@yahoo.com",
            status: "BIM-Bim",
            created: "21-09-2022",
          },
          {
            name: "Esengün Eraslan",
            avator: require('@/assets/images/user/7.jpg'),
            email: "dilhusakcay@hotmail.com",
            status: "MIG-Migros",
            created: "31-01-2023",
          },
          {
            name: "Hurşide Teknaz",
            avator: require('@/assets/images/user/7.jpg'),
            email: "rumeysabilge@cetin.biz",
            status: "BIM-Bim",
            created: "16-10-2023",
          },
        ],
      };
    },
  };
  