<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header pb-0">
          <h3>Yeni Kullanıcı Oluştur</h3>
        </div>
        <div class="card-body">
          <form class="theme-form mega-form">
            <div class="row">
              <div class="col-md-4 mb-3">
                <label class="col-form-label">Ad Soyad</label>
                <input
                  class="form-control"
                  type="text"
                  placeholder="Ad Soyad"
                />
              </div>
              <div class="col-md-4 mb-3">
                <label class="col-form-label">E-Posta Adresi</label>
                <input
                  class="form-control"
                  type="text"
                  placeholder="E-Posta Adresi"
                />
              </div>
              <div class="col-md-4 mb-3">
                <label class="col-form-label">Profil Fotoğrafı</label>
                <input class="form-control" type="file" />
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 mb-3">
                <label class="col-form-label">Firma</label>
                <select class="form-select">
                  <option>BIM-Bim</option>
                  <option>MIG-Migros</option>
                  <option>A101-A101</option>
                </select>
              </div>
            </div>
          </form>
        </div>
        <div class="card-footer text-end d-flex justify-content-between">
          <button class="btn btn-light">
            <vue-feather size="15" type="arrow-left"></vue-feather> Vazgeç
          </button>
          <button class="btn btn-primary">
            <vue-feather size="15" type="plus-square"></vue-feather> Kaydet
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
