export default {
  data() {
    return {
      searchValue: "",
      headers: [
        {
          text: "Mağaza Kodu",
          value: "code",
          sortable: true,
        },
        {
          text: "Mağaza Tipi",
          value: "storeType",
          sortable: true,
        },
        {
          text: "Mağaza Adı",
          value: "name",
          sortable: true,
        },
        {
          text: "Adres",
          value: "address",
          sortable: true,
        },

        {
          text: "Cihaz Kodu",
          value: "city",
          sortable: true,
        },
        {
          text: "",
          value: "actions",
          sortable: false,
          align: "direction-left",
        },
      ],
      Item: [
        {
          code: "BİM-732",
          storeType: "Depo",
          name: "Üsküdar Şubesi",
          address: "Dincer Hollow No: 97",
          city: "482237001",
          avator: require('@/assets/images/user/7.jpg'),
          created: "05-01-2024",
        },
        {
          code: "ŞOK-487",
          storeType: "Express",
          name: "Beyoğlu Şubesi",
          address: "Ferinaz Lake No: 93",
          city: "Cihaz Eklenmemiş",
          avator: require('@/assets/images/user/7.jpg'),
          created: "27-05-2022",
        },
        {
          code: "MİGROS-547",
          storeType: "Market",
          name: "Bağcılar Şubesi",
          address: "Çetin Bridge No: 83",
          city: "482237001",
          avator: require('@/assets/images/user/7.jpg'),
          created: "27-01-2024",
        },
        {
          code: "A101-757",
          storeType: "3M",
          name: "Beyoğlu Şubesi",
          address: "Gelengül Drive No: 39",
          city: "482237001",
          avator: require('@/assets/images/user/7.jpg'),
          created: "30-12-2022",
        },
        {
          code: "ŞOK-523",
          storeType: "3M",
          name: "Eyüp Şubesi",
          address: "İhsanoğlu Street No: 33",
          city: "Cihaz Eklenmemiş",
          avator: require('@/assets/images/user/7.jpg'),
          created: "04-06-2023",
        },
        {
          code: "CARREFOUR-289",
          storeType: "Depo",
          name: "Beyoğlu Şubesi",
          address: "Yasan Station No: 49",
          city: "482237001",
          avator: require('@/assets/images/user/7.jpg'),
          created: "23-06-2022",
        },
        {
          code: "ŞOK-612",
          storeType: "Depo",
          name: "Şişli Şubesi",
          address: "Arsoy Walks No: 75",
          city: "482237001",
          avator: require('@/assets/images/user/7.jpg'),
          created: "24-07-2022",
        },
        {
          code: "MİGROS-529",
          storeType: "3M",
          name: "Şişli Şubesi",
          address: "Almus Parkways No: 84",
          city: "482237001",
          avator: require('@/assets/images/user/7.jpg'),
          created: "22-12-2022",
        },
        {
          code: "A101-555",
          storeType: "Market",
          name: "Fatih Şubesi",
          address: "Şensoy Plaza No: 11",
          city: "Cihaz Eklenmemiş",
          avator: require('@/assets/images/user/7.jpg'),
          created: "28-01-2024",
        },
        {
          code: "MİGROS-201",
          storeType: "Express",
          name: "Fatih Şubesi",
          address: "Türk Mall No: 18",
          city: "482237001",
          avator: require('@/assets/images/user/7.jpg'),
          created: "05-02-2023",
        },
        {
          code: "MİGROS-723",
          storeType: "3M",
          name: "Bağcılar Şubesi",
          address: "Özdal Island No: 38",
          city: "482237001",
          avator: require('@/assets/images/user/7.jpg'),
          created: "14-01-2022",
        },
        {
          code: "CARREFOUR-467",
          storeType: "Depo",
          name: "Maltepe Şubesi",
          address: "İlim Union No: 8",
          city: "482237001",
          avator: require('@/assets/images/user/7.jpg'),
          created: "16-12-2022",
        },
        {
          code: "CARREFOUR-335",
          storeType: "Market",
          name: "Kadıköy Şubesi",
          address: "Gül Stream No: 57",
          city: "482237001",
          avator: require('@/assets/images/user/7.jpg'),
          created: "04-07-2022",
        },
        {
          code: "CARREFOUR-833",
          storeType: "Depo",
          name: "Esenler Şubesi",
          address: "Karadeniz Key No: 75",
          city: "482237001",
          avator: require('@/assets/images/user/7.jpg'),
          created: "14-09-2023",
        },
        {
          code: "ŞOK-876",
          storeType: "Market",
          name: "Avcılar Şubesi",
          address: "Akça Course No: 15",
          city: "482237001",
          avator: require('@/assets/images/user/7.jpg'),
          created: "05-11-2023",
        },
      ],
    };
  },
};
