<template>
    <div class="container-fluid">
      <Breadcrumbs main="Store Type Create"/>
    </div>
  </template>
  
  <script lang="ts" setup>
  
  </script>
  
  <style>
  
  </style>