export var menuItems = {
  data: [
    {
      title: "Anasayfa",
      icon: "stroke-home",
      iconf: "fill-home",
      type: "link",
      active: false,
    },
    {
      title: "Firma Yönetimi",
      icon: "stroke-project",
      iconf: "fill-project",
      type: "sub",
      active: false,
      children: [
        {
          path: "/tenant/list",
          title: "Firma listesi",
          type: "link",
          active: false,
        },
        {
          path: "/tenant/create",
          title: "Yeni oluştur",
          type: "link",
          active: false,
        },
      ],
    },
    {
      title: "Firma Kullanıcıları",
      icon: "stroke-user",
      iconf: "fill-user",
      type: "sub",
      active: false,
      children: [
        {
          path: "/user/list",
          title: "Kullanıcı listesi",
          type: "link",
          active: false,
        },
        {
          path: "/user/create",
          title: "Yeni oluştur",
          type: "link",
          active: false,
        },
      ],
    },
    {
      title: "Mağazalar",
      icon: "stroke-user",
      iconf: "fill-user",
      type: "sub",
      active: false,
      children: [
        {
          path: "/store/list",
          title: "Mağaza listesi",
          type: "link",
          active: false,
        },
        {
          path: "/store/create",
          title: "Yeni oluştur",
          type: "link",
          active: false,
        },
      ],
    },
    {
      title: "Mağaza Tipleri",
      icon: "stroke-user",
      iconf: "fill-user",
      type: "sub",
      active: false,
      children: [
        {
          path: "/store-type/list",
          title: "Listele",
          type: "link",
          active: false,
        },
        {
          path: "/store-type/create",
          title: "Yeni oluştur",
          type: "link",
          active: false,
        },
      ],
    },
    {
      title: "Genel Sensör Ayarları",
      icon: "stroke-user",
      iconf: "fill-user",
      type: "sub",
      active: false,
      children: [
        {
          path: "/general-sensor-settings/list",
          title: "Listele",
          type: "link",
          active: false,
        },
        {
          path: "/general-sensor-settings/create",
          title: "Yeni oluştur",
          type: "link",
          active: false,
        },
      ],
    },
  ],
};
