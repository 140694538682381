<template>
  <div class="container-fluid">
    <Breadcrumbs main="General Sensor List"/>
  </div>
</template>

<script lang="ts" setup>

</script>

<style>

</style>