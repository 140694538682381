<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header pb-0">
          <h3>Yeni Firma Oluştur</h3>
        </div>
        <div class="card-body">
          <form class="theme-form mega-form">
            <div class="row">
              <div class="col-md-4 mb-3">
                <label class="col-form-label">Firma Kodu</label>
                <input
                  class="form-control"
                  type="text"
                  placeholder="Firma Kodu"
                />
              </div>
              <div class="col-md-4 mb-3">
                <label class="col-form-label">Firma Adı</label>
                <input
                  class="form-control"
                  type="text"
                  placeholder="Firma Adı"
                />
              </div>
              <div class="col-md-4 mb-3">
                <label class="col-form-label">Firma Logosu</label>
                <input class="form-control" type="file" />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 mb-3">
                <label class="col-form-label">Aktiflik Durumu</label>
                <div class="btn-radio">
                  <div class="btn-group" data-bs-toggle="buttons">
                    <div class="btn btn-success active">
                      <div class="radio radio-success">
                        <input
                          id="radio7"
                          type="radio"
                          name="radio1"
                          value="option1"
                          checked
                        />
                        <label class="m-0" for="radio7">Aktif</label>
                      </div>
                    </div>
                    <div class="btn btn-primary">
                      <div class="radio radio-primary">
                        <input
                          id="radio8"
                          type="radio"
                          name="radio1"
                          value="option1"
                        />
                        <label class="m-0" for="radio8">Pasif</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="card-footer text-end d-flex justify-content-between">
          <button class="btn btn-light">
            <vue-feather size="15" type="arrow-left"></vue-feather> Vazgeç
          </button>
          <button class="btn btn-primary">
            <vue-feather size="15" type="plus-square"></vue-feather> Kaydet
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
