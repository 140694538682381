import { createRouter, createWebHistory } from "vue-router";
import Body from "../components/body";
import Default from "../pages/dashboard/defaultPage.vue";
import LoginPage from "../pages/auth/login.vue";
import ForgotPassword from "../pages/auth/forgot_password.vue";
import TenantList from "../pages/tenant/list.vue";
import TenantCreate from "../pages/tenant/create.vue";
import UserList from "../pages/user/list.vue";
import UserCreate from "../pages/user/create.vue";
import StoreList from "../pages/store/list.vue";
import StoreCreate from "../pages/store/create.vue";
import StoreTypeCreate from "../pages/store_type/create.vue";
import StoreTypeList from "../pages/store_type/list.vue";
import GeneralSensorSettingsList from "../pages/general_sensor_settings/list.vue";
import GeneralSensorSettingsCreate from "../pages/general_sensor_settings/list.vue";

const routes = [
  {
    path: "/",
    component: Body,

    children: [
      {
        path: "",
        name: "defaultRoot",
        component: Default,
      },
    ],
  },
  {
    path: "/auth/login",
    name: "LoginPage",
    component: LoginPage,
    meta: {
      title: "Giriş Yap | ETA Mühendislik",
    },
  },
  {
    path: "/auth/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
    meta: {
      title: "Şifre Sıfırla | ETA Mühendislik",
    },
  },
  {
    path: "/tenant",
    component: Body,

    children: [
      {
        path: "/tenant/list",
        name: "TenantList",
        component: TenantList,
      },
      {
        path: "/tenant/create",
        name: "TenantCreate",
        component: TenantCreate,
      },
    ],
  },
  {
    path: "/user",
    component: Body,

    children: [
      {
        path: "/user/list",
        name: "UserList",
        component: UserList,
      },
      {
        path: "/user/create",
        name: "UserCreate",
        component: UserCreate,
      },
    ],
  },
  {
    path: "/store",
    component: Body,

    children: [
      {
        path: "/store/list",
        name: "StoreList",
        component: StoreList,
      },
      {
        path: "/store/create",
        name: "StoreCreate",
        component: StoreCreate,
      },
    ],
  },
  {
    path: "/store-type",
    component: Body,

    children: [
      {
        path: "/store-type/list",
        name: "StoreTypeList",
        component: StoreTypeList,
      },
      {
        path: "/store-type/create",
        name: "StoreTypeCreate",
        component: StoreTypeCreate,
      },
    ],
  },
  {
    path: "/general-sensor-settings",
    component: Body,

    children: [
      {
        path: "/general-sensor-settings/list",
        name: "GeneralSensorSettingsList",
        component: GeneralSensorSettingsList,
      },
      {
        path: "/general-sensor-settings/create",
        name: "GeneralSensorSettingsCreate",
        component: GeneralSensorSettingsCreate,
      },
    ],
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
});
// router.beforeEach((to, from, next) => {
//   if (to.meta.title) document.title = to.meta.title;
//   const path = ["/auth/login", "/auth/register"];
//   if (path.includes(to.path) || localStorage.getItem("User")) {
//     return next();
//   }
//   next("/auth/login");
// });
export default router;
