<template>
  <div>
    <div class="d-flex justify-content-between align-items-end mb-3">
      <div>
        <label class="form-label">Mağazalarda Ara:</label>
        <input
          type="search"
          v-model="searchValue"
          class="form-control"
          placeholder="Aranacak kelime yazın..."
          aria-controls="basic-6"
          data-bs-original-title=""
          title=""
        />
      </div>
      <router-link to="/store/create" class="btn btn-sm btn-success"
        ><vue-feather size="15" type="shopping-cart"></vue-feather> Yeni Mağaza
        Ekle</router-link
      >
    </div>
    <table class="table display dataTable" id="basic-6">
      <EasyDataTable
        :headers="headers"
        rowsPerPage="10"
        :items="Item"
        :search-field="searchField"
        :search-value="searchValue"
        alternating
      >
        <template #item-actions="item">
          <div class="d-flex justify-content-end align-items-center gap-2 py-1">
            <button class="btn btn-sm btn-danger"><vue-feather size="15" type="settings"></vue-feather> Sensör Ayarları</button>
            <button class="btn btn-sm btn-warning"><vue-feather size="15" type="activity"></vue-feather> Sensör Verileri</button>
            <button class="btn btn-sm btn-info"><vue-feather size="15" type="edit-3"></vue-feather> Düzenle</button>
            <button class="btn btn-sm btn-primary">
              <vue-feather size="15" type="x"></vue-feather> Sil
            </button>
          </div>
        </template>
      </EasyDataTable>
    </table>
  </div>
</template>

<script>
import storeTable from "@/mixins/storeTable";
export default {
  mixins: [storeTable],
};
</script>
