<template>
  <div>
    <div class="d-flex justify-content-between align-items-end mb-3">
      <div>
        <label class="form-label">Firmalarda Ara:</label>
        <input
          type="search"
          v-model="searchValue"
          class="form-control"
          placeholder="Aranacak kelime yazın..."
          aria-controls="basic-6"
          data-bs-original-title=""
          title=""
        />
      </div>
      <router-link to="/tenant/create" class="btn btn-sm btn-success"
        ><vue-feather size="15" type="globe"></vue-feather> Yeni Firma
        Ekle</router-link
      >
    </div>
    <table class="table display dataTable" id="basic-6">
      <EasyDataTable
        :headers="headers"
        rowsPerPage="10"
        :items="Item"
        :search-field="searchField"
        :search-value="searchValue"
        alternating
      >
        <template #item-name="{ name, avator }">
          <div class="media">
            <img
              class="rounded-circle img-30 me-3"
              :src="avator"
              alt="Generic placeholder image"
            />
            <div class="media-body align-self-center">
              <div>{{ name }}</div>
            </div>
          </div>
        </template>
        <template #item-actions="item">
          <div class="d-flex justify-content-end align-items-center gap-2 py-1">
            <button class="btn btn-sm btn-warning">
              <vue-feather size="15" type="users"></vue-feather> Firma
              Yöneticileri
            </button>
            <button class="btn btn-sm btn-info">
              <vue-feather size="15" type="edit-3"></vue-feather> Düzenle
            </button>
            <button class="btn btn-sm btn-primary">
              <vue-feather size="15" type="x"></vue-feather> Sil
            </button>
          </div>
        </template>
      </EasyDataTable>
    </table>
  </div>
</template>

<script>
import tenantTable from "@/mixins/tenantTable";
export default {
  mixins: [tenantTable],
};
</script>
