<template>
  <div class="container-fluid">
    <Breadcrumbs main="" />
    <div class="row starter-main">
      <div class="col-sm-12">
        <div class="row">
          <CreateForm />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CreateForm from "@/components/tenant/createForm.vue";
export default {
  components: {
    CreateForm,
  },
};
</script>

<style></style>
