<template>
  <div class="container-fluid">
    <Breadcrumbs main="Mağaza Oluştur" />
    <div class="row starter-main">
      <div class="col-sm-12">
        <CreateForm />
      </div>
    </div>
  </div>
</template>

<script>
import CreateForm from "@/components/store/createForm.vue";
export default {
  components: {
    CreateForm,
  },
};
</script>

<style></style>
