export default {
  data() {
    return {
      searchValue: "",
      headers: [
        {
          text: "Firma Kodu",
          value: "code",
          sortable: true,
        },
        {
          text: "Firma Adı",
          value: "name",
          sortable: true,
        },
       
        {
          text: "Aktiflik Durumu",
          value: "status",
          sortable: true,
        },

        {
          text: "Oluşturulma Tarihi",
          value: "created",
          sortable: true,
        },

        {
          text: "",
          value: "actions",
          sortable: false,
          align: "direction-left",
        },
      ],
      Item: [
        {
          code: 'KIP',
          name: "Kipa",
          avator: require("@/assets/images/user/7.jpg"),
          address: "Mersin / Türkiye",
          status: "Aktif",
          created: "05-04-2023",
        },
        {
          code: 'SOK',
          name: "Şok",
          avator: require("@/assets/images/user/7.jpg"),
          address: "İstanbul / Türkiye",
          status: "Aktif",
          created: "22-12-2023",
        },
        {
          code: 'BIM',
          name: "Bim",
          avator: require("@/assets/images/user/7.jpg"),
          address: "Gaziantep / Türkiye",
          status: "Aktif",
          created: "17-04-2023",
        },
        {
          code: 'CAR',
          name: "CarrefourSA",
          avator: require("@/assets/images/user/7.jpg"),
          address: "Bursa / Türkiye",
          status: "Pasif",
          created: "25-10-2023",
        },
        {
          code: 'MET',
          name: "Metro",
          avator: require("@/assets/images/user/7.jpg"),
          address: "Bursa / Türkiye",
          status: "Pasif",
          created: "02-02-2023",
        },
        {
          code: 'KIP',
          name: "Kipa",
          avator: require("@/assets/images/user/7.jpg"),
          address: "Mersin / Türkiye",
          status: "Pasif",
          created: "04-08-2023",
        },
        {
          code: 'SOK',
          name: "Şok",
          avator: require("@/assets/images/user/7.jpg"),
          address: "Mersin / Türkiye",
          status: "Aktif",
          created: "02-04-2024",
        },
        {
          code: 'A101',
          name: "A101",
          avator: require("@/assets/images/user/7.jpg"),
          address: "İstanbul / Türkiye",
          status: "Aktif",
          created: "18-07-2023",
        },
        {
          code: 'MIG',
          name: "Migros",
          avator: require("@/assets/images/user/7.jpg"),
          address: "Kayseri / Türkiye",
          status: "Pasif",
          created: "04-10-2023",
        },
        {
          code: 'A101',
          name: "A101",
          avator: require("@/assets/images/user/7.jpg"),
          address: "Ankara / Türkiye",
          status: "Pasif",
          created: "07-07-2023",
        },
        {
          code: 'MET',
          name: "Metro",
          avator: require("@/assets/images/user/7.jpg"),
          address: "Gaziantep / Türkiye",
          status: "Aktif",
          created: "01-11-2023",
        },
        {
          code: 'A101',
          name: "A101",
          avator: require("@/assets/images/user/7.jpg"),
          address: "Adana / Türkiye",
          status: "Pasif",
          created: "25-02-2024",
        },
        {
          code: 'MIG',
          name: "Migros",
          avator: require("@/assets/images/user/7.jpg"),
          address: "Mersin / Türkiye",
          status: "Aktif",
          created: "02-01-2023",
        },
        {
          code: 'TAN',
          name: "Tansaş",
          avator: require("@/assets/images/user/7.jpg"),
          address: "Ankara / Türkiye",
          status: "Pasif",
          created: "16-02-2024",
        },
        {
          code: 'MIG',
          name: "Migros",
          avator: require("@/assets/images/user/7.jpg"),
          address: "Gaziantep / Türkiye",
          status: "Aktif",
          created: "27-02-2024",
        },
      ],
    };
  },
};
