<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header pb-0">
          <h3>Yeni Mağaza Oluştur</h3>
        </div>
        <div class="card-body">
          <form class="theme-form mega-form">
            <div class="row">
              <div class="col-md-4 mb-3">
                <label class="col-form-label">Mağaza Kodu</label>
                <input
                  class="form-control"
                  type="text"
                  placeholder="Mağaza Kodu"
                />
              </div>
              <div class="col-md-4 mb-3">
                <label class="col-form-label">Mağaza Adı</label>
                <input
                  class="form-control"
                  type="text"
                  placeholder="Mağaza Adı"
                />
              </div>
              <div class="col-md-4 mb-3">
                <label class="col-form-label">Mağaza Tipi</label>
                <select class="form-select">
                  <option>Depo</option>
                  <option>3M</option>
                  <option>4M</option>
                  <option>Şube</option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 mb-3">
                <label class="col-form-label">Cihaz</label>
                <v-select class="style-chooser" :options="['482237001', '6894524762', '124347678']"></v-select>
              </div>
              <div class="col-md-4 mb-3">
                <label class="col-form-label">Şehir</label>
                <select class="form-select">
                  <option>İstanbul</option>
                  <option>Ankara</option>
                  <option>Kayseri</option>
                  <option>Adana</option>
                  <option>Bursa</option>
                </select>
              </div>
              <div class="col-md-4 mb-3">
                <label class="col-form-label">Adres</label>
                <input class="form-control" type="text" placeholder="Adres" />
              </div>
            </div>
          </form>
        </div>
        <div class="card-footer text-end d-flex justify-content-between">
          <button class="btn btn-light">
            <vue-feather size="15" type="arrow-left"></vue-feather> Vazgeç
          </button>
          <button class="btn btn-primary">
            <vue-feather size="15" type="plus-square"></vue-feather> Yeni Mağaza
            Oluştur
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: #fff;
  border: 1px solid #efefef;
  color: #394066;
  text-transform: lowercase;
  font-variant: small-caps;
}

.style-chooser .vs__search{
  padding: 8px 8px !important;
  border: 0;
}

.style-chooser #vs1__listbox{
  padding: 15px 0
}

.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  fill: #394066;
}
</style>