<template>

    <div class="container-fluid">
        <Breadcrumbs main="Eta Ölçüm"/>

        <div class="row starter-main">
          
        </div>
      </div>
    <!-- </div> -->
</template>