<template>
  <div>
    <div class="page-wrapper">
      <div class="container-fluid p-0">
        <div class="row">
          <div class="col-12">
            <div class="login-card">
              <div>
                <div>
                  <a class="logo">
                    <img
                      class="img-fluid for-light"
                      src="../../assets/logo-black.png"
                      alt="looginpage"
                    />
                    <img
                      class="img-fluid for-dark"
                      src="../../assets/logo-black.png"
                      alt="looginpage"
                    />
                  </a>
                </div>
                <div class="login-main">
                  <form class="theme-form" @submit.prevent="">
                    <h4>Şifre Sıfırla</h4>
                    <div class="form-group">
                      <label class="col-form-label"
                        >Telefon Numaranızı Girin</label
                      >
                      <div class="row">
                        <div class="col-4 col-sm-3">
                          <input
                            class="form-control mb-1"
                            type="text"
                            value="+90"
                          />
                        </div>
                        <div class="col-8 col-sm-9">
                          <input
                            class="form-control mb-1"
                            type="tel"
                            value="000-000-0000"
                          />
                        </div>
                        <div class="col-12">
                          <div class="text-end">
                            <button class="btn btn-primary btn-block m-t-10">
                              Kod Gönder
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: true,
    };
  },
  methods: {
    show() {
      this.active = !this.active;
    },
  },
};
</script>
