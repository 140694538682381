<template>
  <div class="container-fluid">
    <Breadcrumbs main="Mağaza Listesi" />
    <div class="row starter-main">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <StoreTable/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StoreTable from "@/components/store/storeTable.vue";
export default {
  components: {
    StoreTable,
  },
};
</script>

<style></style>
