<template>
  <li class="profile-nav onhover-dropdown pe-0 py-0">
    <div class="media profile-media">
      <img class="b-r-10" src="@/assets/images/dashboard/profile.png" alt="" />
      <div class="media-body">
        <span>Burak Fidancı</span>
        <p class="mb-0 font-roboto">
          SuperAdmin <i class="middle fa fa-angle-down"></i>
        </p>
      </div>
    </div>
    <ul class="profile-dropdown onhover-show-div">
      <li><vue-feather type="user"></vue-feather><span>Proflim </span></li>

      <li><vue-feather type="log-in"></vue-feather><span>Çıkış Yap</span></li>
    </ul>
  </li>
</template>

<script>
// import firebase from 'firebase';
// import UserAuth from '../../auth/js/index';

export default {
  name: "Profile",
  methods: {
    logout: function () {
      // firebase
      //   .auth()
      //   .signOut()
      //   .then(() => {
      //     UserAuth.Logout();
      this.$router.replace("/auth/login");
      localStorage.removeItem("User");
    },
    // },
  },
};
</script>
